$(document).ready(function(){

    // navi burger
    $('.col-right #nav-icon').click(function() {
        event.stopPropagation();
        $(".col-right #nav-icon").toggleClass("open");
        $(".col-right nav.servicenavi").toggle();
    });

    // navi burger mobile
    $('.mobile-header  #nav-icon-mobile').click(function() {
        event.stopPropagation();
        $(".mobile-header #nav-icon-mobile").toggleClass("open");
        $(".mobile-header nav").toggle();
    });

    $('.showLongtext').click(function() {
        $(".longtext").hide();
        $(".showLongtext").show();
        var dataId = $(this).data("id");
        $(".longtext-" + dataId).toggle();
        $(".showLongtext-" + dataId).toggle();
    });

    $('.hideLongtext').click(function() {
        var dataId = $(this).data("id");
        $(".longtext-" + dataId).toggle();
        $(".showLongtext-" + dataId).toggle();
    });


    $('.col-imgwrapper').hover(
        function() {
            $( this ).addClass( "active" );
        }, function() {
            $( this ).removeClass( "active" );
        }
    );


    $("#carouselProduktbilder img").click(function() {
        console.log('next');
        $("#carouselProduktbilder").carousel('next');

    });


    lightbox.option({
        'fadeDuration': 100,
        'imageFadeDuration': 100,
        'resizeDuration': 100,
        'showImageNumberLabel': false
    })

    $(".address-country").change(function() {
        if (this.value == "callUs") {
            $(".cartwrapper form .belchenButton").hide();
        } else {
            $(".cartwrapper form .belchenButton").show();
        }
    });

    $("#agb").change(function() {
        if($("#agb").is(':checked')) {
            $(".btn-disabled").removeClass('disabled');  // checked
            $(".btn-disabled").prop('disabled', false);
        }
        else {
            $(".btn-disabled").addClass('disabled');  // checked
            $(".btn-disabled").prop('disabled', true);
        }
    });


    $(".anzahl-wrapper .number").change(function() {
        if (this.value > 9) {
            $( this ).addClass( "numberBig" );
        } else {
            $( this ).removeClass( "numberBig" );
        }
        $( "#cart-form" ).submit();
    });

    $(".anzahl-wrapper .arrow-left").click(function() {
        var dataId = $(this).data("id");

        var oldVal = $('.anzahl-wrapper .number').val();
        var newVal = oldVal*1 - 1;
        if (newVal < 10) {
            $( ".anzahl-wrapper .number." + dataId ).removeClass( "numberBig" );
        }
        if (newVal < 1) {
            newVal = 1;
        }
        $( ".anzahl-wrapper .number." + dataId).val(newVal);
        $( "#cart-form" ).submit();
    });

    $(".anzahl-wrapper .arrow-right").click(function() {
        var dataId = $(this).data("id");

        var oldVal = $('.anzahl-wrapper .number').val();
        var newVal = oldVal*1 + 1;
        if (newVal > 9) {
            $( ".anzahl-wrapper .number." + dataId ).addClass( "numberBig" );
        }
        if (newVal > 99) {
            newVal = 99;
        }
        $( ".anzahl-wrapper .number." + dataId).val(newVal);
        $( "#cart-form" ).submit();
    });

    function billingbox() {
        if($("#billingAddressSameAsShipping").is(':checked')) {
            $(".wrapperRechnungsadresse").hide();
        }
        else {
            $(".wrapperRechnungsadresse").show();
        }
    }

    billingbox();

    $("#billingAddressSameAsShipping").click(function() {
        billingbox();
    });

});